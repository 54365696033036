// store.js

import { createStore } from 'vuex'



export default createStore({
  state: {
    token: null,
    cusID: null,
    sidebarVisible: '',
    sidebarUnfoldable: false,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_CUSID(state,cusID) {
      state.cusID = cusID
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  actions: {
    setToken({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    setCusID({ commit }, cusID) {
      commit('SET_CUSID', cusID)
    }
  },
  getters: {
    getToken: state => state.token,
    getCusID: state => state.cusID
  }
})
