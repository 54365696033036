<template>
     <div class="container" id="content">
      <form @submit.prevent="login">
        <h2 class="mb-3">Login</h2>
        <div class="input">
          <label for="email">Email address</label>
          <input
            class="form-control"
            type="text"
            name="email"
            placeholder="email@adress.com"
            v-model="email"
          />
        </div>
        <div class="input">
          <label for="password">Password</label>
          <input
            class="form-control"
            type="password"
            name="password"
            placeholder="password123"
            v-model="password"
          />
        </div> 
        <button type="submit" class="mt-4 btn-pers" id="login_button">
          Login
        </button>
        <div
          class="alert alert-warning alert-dismissible fade show mt-5 d-none"
          role="alert"
          id="alert_1"
        >
          Lorem ipsum dolor sit amet consectetur, adipisicing elit.
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      </form>
    </div>
  </template>
  <script>
  import axios from 'axios'
  import { mapActions } from 'vuex'
  
  export default {
    data() {
      return {
        email: '',
        password: ''
      }
    },
    methods: {
      ...mapActions(['setToken']),
      ...mapActions(['setCusID']),
      async login() {
        try {
          const response = await axios.post(`${window.location.origin}/v1/login`, {
            email: this.email,
            password: this.password,
          }, {
            headers: {
              'Content-Type': 'multipart/form-data' 
            }
          }) 
          if(response.data.s == '200'){ 
            this.setToken(response.data.data.token)
            this.setCusID(response.data.data.cusID) 
            this.$router.push("/dashboard");

          }
          else alert("Đăng nhập thất bại")
        } catch (error) {
          console.log(error.response)
        }
      }
    }
  }
  </script>
  <style>
#content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border: 1px solid #d3d3d3;
    padding: 4rem 4rem;
    border-radius: 5px;
    background: #fefefe
}

.background {
    height: 100vh
}

.background,.navbar {
    width: 100vw;
    position: absolute;
    background: #a3a1a1
}

.navbar {
    top: 0;
    height: 70px;
    box-shadow: 6.7px 6.7px 5.3px rgba(0,0,0,.028),22.3px 22.3px 17.9px rgba(0,0,0,.042),100px 100px 80px rgba(0,0,0,.07)
}

.nav {
    width: 80%;
    min-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #fafafa
}

#name_project {
    font-weight: 700
}

.container {
    width: 400px;
    max-width: 95%
}

.input {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px
}

.input>label {
    text-align: start
}

.input>input {
    margin-top: 6px;
    height: 38px!important
}

.btn-pers {
    position: relative;
    left: 50%;
    padding: 1em 2.5em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 700;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0 8px 15px rgba(0,0,0,.1);
    transition: all .3s ease 0s;
    cursor: pointer;
    outline: none;
    transform: translateX(-50%)
}

.btn-pers:hover {
    background-color: #198754;
    box-shadow: 0 15px 20px rgba(46,229,157,.4);
    color: #fff;
    transform: translate(-50%,-7px)
}

.btn-pers:active {
    transform: translate(-50%,-1px)
}

.alternative-option {
    text-align: center
}

.alternative-option>span {
    color: #0d6efd;
    cursor: pointer
}

#sign_out {
    position: relative;
    left: 50%;
    transform: translateX(-50%)
}

</style>
  