<template>
  <CFooter>
    <div>
      <a href="https://eparking.vn/" target="_blank">An Phú Việt</a>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} eParking.</span
      >
    </div> 
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
