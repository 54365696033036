<template>
  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
    <CCol md="4">
      <CInputGroup class="has-validation">
        <CFormInput id="searchKey" />
        <CButton color="success" @click="search()">Search</CButton> 
      </CInputGroup> 
    </CCol>  
    <CButton color="primary" @click="() => { modalExcel = true }">Excel</CButton>
  </div> 
  <div>
    <CTable striped>
      <CTableHead>
        <CTableRow>
          <CTableHeaderCell scope="col">Mã user</CTableHeaderCell>
          <CTableHeaderCell scope="col">Ngày tạo</CTableHeaderCell>
          <CTableHeaderCell scope="col">Tạo bởi</CTableHeaderCell> 
          <CTableHeaderCell scope="col">Loại</CTableHeaderCell>
          <CTableHeaderCell scope="col">Nội dung</CTableHeaderCell>
          <CTableHeaderCell scope="col">Số tiền trước đó</CTableHeaderCell>
          <CTableHeaderCell scope="col">Số tiền đang có</CTableHeaderCell> 
        </CTableRow>
      </CTableHead>
      <CTableBody v-if="items.length > 0">
        <CTableRow v-for="(item, index) in items" :key="item.id" :id="item.id"> 
          <CTableDataCell>{{ item.beneficiaryAccount }}</CTableDataCell>
          <CTableDataCell>{{ formatDateTime(item.createdAt) }}</CTableDataCell>
          <CTableDataCell>{{ item.createdBy }}</CTableDataCell> 
          <CTableDataCell>{{ item.type }}</CTableDataCell>
          <CTableDataCell>{{ item.content }}</CTableDataCell>
          <CTableDataCell>{{ formatMoney(item.amountBefore) }}</CTableDataCell>
          <CTableDataCell>{{ formatMoney(item.amountAfter) }}</CTableDataCell>  
        </CTableRow>
      </CTableBody>
    </CTable>
    <CPagination v-model="currentPage" :total-pages="totalPages">
      <CPaginationItem :disabled="currentPage === 1" @click="setCurrentPage(currentPage - 1)">
        Previous
      </CPaginationItem>
      <CPaginationItem v-for="pageNumber in totalPages" :key="pageNumber" @click="setCurrentPage(pageNumber)">
        {{ pageNumber }}
      </CPaginationItem>
      <CPaginationItem :disabled="currentPage === totalPages" @click="setCurrentPage(currentPage + 1)">
        Next
      </CPaginationItem>
    </CPagination>
  
  </div>
</template> 
<script>
import moment from 'moment';
import store from '@/store'
import axios from 'axios'
import { CPagination } from '@coreui/vue'
import { CPaginationItem } from '@coreui/vue'; 
import { v4 as uuidv4 } from 'uuid'; 
import { read, utils, writeFileXLSX } from 'xlsx';

 
export default {
  name: 'Management',
  computed: {
    
  },
  components: {
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 0,
      itemsPerPage: 100,
      items: [],
      totalUsers: 0,
      modalCreate: false,
      modalUpdate: false,
      modalViewMore: false,
      modalDepositMoney: false,
      modalExcel: false,
      selectedUser: {},
      keyword: '',
      token: '',
      selectedItems : '',

      fullNameC  : '',
      maNVC : '',
      emailC : '',
      cCCDC : '',
      accountNo1C : '',
      accountNo2C : '',
      plateC : '',
      massDepositMoneyJson: []
    }
  },
  methods: {
    formatMoney(amount) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDateTime(dateTime) {
      return moment(dateTime, 'YYYYMMDDHHmmss').format('DD-MM-YYYY HH:mm:ss');
    },
    openViewModal(id) {
      this.modalViewMore = true;
      this.selectedUser = this.items.find(dt => dt.id == id);
    }, 
    openUpdateModal(id) {
      this.modalUpdate = true;
      this.selectedUser = this.items.find(dt => dt.id == id);
    }, 
    openDepositMoneyModal(id) {
      this.modalDepositMoney = true;
      this.selectedUser = this.items.find(dt => dt.id == id);
    },
    async setCurrentPage(pageNumber) {
      this.currentPage = pageNumber;
      console.log("CurrentPage" + this.currentPage);
    }, 
    async getTotalByKeyword() {
      await axios.get(`/v1/statement/quantity/${this.keyword}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + this.token,
        }
      })
        .then(response => { 
          this.totalUsers = response.data.data;
          this.totalPages = Math.ceil(this.totalUsers / this.itemsPerPage)

        })
        .catch(error => { 
          console.log(error);
        });
    },

    async search() {
      this.keyword = document.getElementById('searchKey').value
      if (this.keyword != '') {
        await this.getTotalByKeyword(); 
        await axios.get(`/v1/statement?accountNo=${this.keyword}&limit=${this.itemsPerPage}&offset=${(this.currentPage - 1) * this.itemsPerPage}`, {
          headers: {
            'Authorization': 'Bearer ' + this.token,
            'Access-Control-Allow-Origin': '*'
          }
        })
          .then(response => {
            this.items = response.data.data;
          })
          .catch(error => {
            console.log(error);
          });
      }
      else{
        this.getTotalAllUser();
      }

    },
 
  },
  watch: {
    currentPage() {
    }
  },
  mounted() { 
  },
} 
</script>


 