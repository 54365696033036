<template>
  <h4>Kiểm tra thông tin dữ liệu khuôn mặt</h4>
  <CForm>
    <CFormInput
      type="text" 
      v-model="searchKey"
      label="Mã GV/SV"
      placeholder="GV980000"
      text="Nhập mã giáo viên hoặc sinh viên" 
    /> 
  </CForm>
  <div class="d-grid gap-2 col-12 mx-auto">
  <CButton color="primary" @click="search()">Tìm kiếm</CButton> 
  </div> 

  <CModal fullscreen :visible="fullscreenDemo" @close="() => { fullscreenDemo = false }">
    <CModalHeader>
      <CModalTitle>Thông tin người dùng</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CRow class="mb-3">  
        <CFormLabel class="col-sm-2 col-form-label">Họ tên</CFormLabel>
        <div class="col-sm-10">
          <CFormInput type="text" id="name" v-model="fullName" readonly plain-text/>
        </div>
      </CRow>
      <CRow class="mb-3">
      <CFormLabel class="col-sm-2 col-form-label">Hình ảnh của bạn</CFormLabel>
      <img :src="pathImage" style=" max-width: 60%;height: auto;  object-fit: contain;" alt="My Image">
      </CRow>

    </CModalBody>
  </CModal>
</template> 
<script>   
import axios from 'axios'  
import config from './config' 

 
export default { 
  data() {
    return { 
      fullName: '', 
      keyword: '',
      pathImage: '', 
      fullscreenDemo: false,
      searchKey: '',
      plate: '',
      faceCenterUrl: config.faceCenterUrl

    }
  },
  methods: { 
    async search() { 
      if (this.searchKey != '') {
        await axios.get(`${config.backendUrl}/v1/user/findbyMaNv?maNv=${this.searchKey}&plate=${this.plate}`, {
          headers: {
            //'Authorization': 'Bearer ' + token
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods':  'GET, POST, PUT, DELETE',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Content-Type' : 'application/json; charset=utf-8'
          }
        })
          .then(response => {
            const data = response.data.data; 
            console.log(data)
            if(data.fullName != null ){ 
              ///http://eparking.tdmu.edu.vn:39019/assets/FaceImage/56378781.jpg
              this.pathImage = `${this.faceCenterUrl}/FaceImage/${data.faceID}.jpg`;
              this.fullName = `${data.fullName}`;
              this.fullscreenDemo = true;
            }
            else alert('Mã không tồn tại')
          })
          .catch(error => {
           console.log(error);
          });
      }
      else alert("Vui lòng nhập mã")
    }
  }
} 
</script>


 