<template>
  <h4>Đăng ký cấp mã kích hoạt khuôn mặt</h4>
  <CForm>
    <CFormInput
      type="text" 
      v-model="searchKey"
      label="Mã GV/SV"
      placeholder="GV980000"
      text="Nhập mã giáo viên hoặc sinh viên"
      aria-describedby="exampleFormControlInputHelpInline"
    />
    <CFormInput
      type="text" 
      v-model="plate"
      label="Biển số xe hay sử dụng"
      placeholder="59a412346|59a199887"
      text="Nhập các biển số hay sử dụng, cách nhau bởi | "
    />
  </CForm>
  <div class="d-grid gap-2 col-12 mx-auto">
  <CButton color="primary" @click="search()">Tạo mã</CButton> 
  </div> 

  <CModal fullscreen :visible="fullscreenDemo" @close="() => { fullscreenDemo = false }">
    <CModalHeader>
      <CModalTitle>Thông tin người dùng</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CRow class="mb-3">  
        <CFormLabel class="col-sm-2 col-form-label">Họ tên</CFormLabel>
        <div class="col-sm-10">
          <CFormInput type="text" id="name" v-model="fullName" readonly plain-text/>
        </div>
      </CRow>
      <CRow class="mb-3">
      <CFormLabel class="col-sm-2 col-form-label">Mã QR code của bạn</CFormLabel>

      <div class="d-grid gap-2 col-12 mx-auto">
        <qrcode-vue :value="value" :size="size" level="H" />
      </div>  
      </CRow>

    </CModalBody>
  </CModal>
</template> 
<script>  
import store from '@/store'
import axios from 'axios'  
import QrcodeVue from 'qrcode.vue'
import config from './config' 

 
export default {
  name: 'Gen QR Code by ',
  components: {
    QrcodeVue,
  },
  data() {
    return { 
      fullName: '',
      plate: '',
      keyword: '',
      token: '',
      value: '',
      size: 300,
      fullscreenDemo: false,
      searchKey: ''
    }
  },
  methods: { 
    async search() { 
      if (this.searchKey != '') {
        if (confirm(`Vui lòng kiểm tra thông tin và xác nhận! \n Mã số : ${this.searchKey} \n Biển số: ${this.plate}`) == false) return
        await axios.get(`${config.backendUrl}/v1/user/findbyMaNv?maNv=${this.searchKey}&plate=${this.plate}`, {
          headers: {
            //'Authorization': 'Bearer ' + token
          }
        })
          .then(response => {
            const data = response.data.data; 
            if(data.fullName != null ){ 
              this.value = `${data.faceID}`;
              this.fullName = `${data.fullName}`;
              this.fullscreenDemo = true;
            }
            else alert('Mã không tồn tại')
          })
          .catch(error => {
            console.log(error);
          });
      }
      else alert("Vui lòng nhập mã")
    }
  }
} 
</script>


 